<template>
    <span v-if="this.$route.params.type =='rgpd'">
        <span>
            <div class="background"></div>
            <div class="inclinaison"></div>
            <div class="div1_page_mise_conformite">
                <img class="produits_img1 will-fadeIn" src="../assets/produits_img1.png">
                <div></div>
                <div class="div1_3 will-fadeIn fadeFromRight">
                    <h1 class="div1_3_1">{{ page_rgpd().bloc1.title }}</h1>
                    <div class="div1_3_2">{{ page_rgpd().bloc1.desc }}</div>
                    <div @click="this.$store.getters.goTo('details')">
                        <div class="boutton_offre">
                            <p class="texte_boutton_offre">{{ page_rgpd().bloc1.bouton }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <div>
            <div class="div2_1">
                <div class="div2_1_1 will-fadeIn">{{ page_rgpd().bloc2.title }}</div>
                <div class="div2_1_2 will-fadeIn fadeFromRight">
                    <div>{{ page_rgpd().bloc2.desc1 }}</div>
                    <div>{{ page_rgpd().bloc2.desc2 }}</div>
                </div>
            </div>
            <div id="details" class="div2_2">
                <div class="div2_2_1">
                    <div class="div2_2_1_1 will-fadeIn">
                        <img class="product_icon" src="../assets/product_icon.png">
                        <h2 class="div2_2_1_1_1">{{ page_rgpd().offre.title }}</h2>
                        <div class="div2_2_1_1_2"></div>
                        <div v-if="getRegularPrice(miseEnConformiteSlug)!=getPrice(miseEnConformiteSlug) && getRegularPrice(miseEnConformiteSlug)!=''" class="div2_2_1_1_3">
                            <div class="div2_2_1_1_3_1">{{ getRegularPrice(miseEnConformiteSlug) }}</div>
                            <div class="div2_2_1_1_3_2"></div>
                            <div class="div2_2_1_1_3_3">€</div>
                        </div>
                        <div class="div2_2_1_1_4">
                            <div class="div2_2_1_1_4_1">{{ getPrice(miseEnConformiteSlug) }}</div>
                            <div class="div2_2_1_1_4_2"></div>
                            <div class="div2_2_1_1_4_3">
                                <div class="div2_2_1_1_4_3_1">€</div>
                                <div class="div2_2_1_1_4_3_2" v-if="getInterval(miseEnConformiteSlug, 1)">{{ getInterval(miseEnConformiteSlug, 1) + global_translate().interval[getInterval(miseEnConformiteSlug, 2)] }}</div>
                            </div>
                        </div>
                        <div class="div2_2_1_1_4_bis">{{ page_rgpd().offre.info_prix }}</div>
                        <div v-if="nbSites==1" class="div2_2_1_1_5">{{ page_rgpd().offre.placeholder_desc }}</div>
                        <div v-else class="div2_2_1_1_5">{{ page_rgpd().offre.placeholder_desc_plural }}</div>
                        <div class="div2_2_1_1_5_bis" v-for="(site, indexSite) in nbSites" :key="indexSite">
                            <input class="champUnique div2_2_1_1_6" type="text" :placeholder="page_rgpd().offre.placeholderP1 + (indexSite+1) + page_rgpd().offre.placeholderP2" v-model.lazy="nomsSitesWeb[indexSite]" />
                        </div>
                        <div class="minus_plus_div">
                            <span v-if="nbSites>1" class="fas color_red pointer" @click="nbSites+=-1">&#xf068;</span>
                            <span class="fas color_green pointer" @click="nbSites+=1">&#xf067;</span>
                        </div>
                        <div class="multisite" v-if="nbSites>1">
                            {{ page_rgpd().offre.multisite.nom + " : " + getPrice(multisiteSlug) + "€ " + page_rgpd().offre.multisite.ht + getInterval(multisiteSlug, 1) + global_translate().interval[getInterval(multisiteSlug, 2)] + " " + page_rgpd().offre.multisite.desc }}
                        </div>
                        <div class="allCheckbox" id="allCheckbox">
                            <div class="checkboxContainer" id="checkbox2">
                                <input class="pointer" type="checkbox" @click="application_type='site'" :checked="application_type=='site'">
                                <span class="checkboxLabel">{{ page_rgpd().offre.checkbox1 }}</span>
                            </div>
                            <div class="checkboxContainer" id="checkbox1">
                                <input class="pointer" type="checkbox" @click="application_type='application'" :checked="application_type=='application'">
                                <span class="checkboxLabel">{{ page_rgpd().offre.checkbox2 }}</span>
                            </div>
                        </div>
                        <div class="div2_2_1_1_7">
                            <div @click="ajouterPanier" class="boutton_offre">
                                <p class="texte_boutton_offre">{{ page_rgpd().offre.bouton }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="div2_2_1_2 will-fadeIn fadeFromRight">
                        <div class="div2_2_1_2_global">
                            <div class="div2_2_1_2_1">{{ page_rgpd().detail.bloc1.title }}</div>
                            <div v-for="(desc, indexDesc) in page_rgpd().detail.bloc1.desc" :key="indexDesc">
                                <div class="div2_2_1_2_4 div2_2_1_2_1_sub"> 
                                    <div>
                                        <!--<i class="fas color_green">&#xf00c;</i>-->
                                        <!--<i class="fas color_green">&#xf054;</i>-->
                                        <i class="fas color_green pointer" @click="demanderDetail(indexDesc)">&#xf05a;</i>
                                    </div>
                                    <div class="div2_2_1_2_4_2">
                                        <span class="pointer pointer_hover" @click="demanderDetail(indexDesc)">{{ desc.title }}</span>
                                        <transition name="slideShort" v-if="desc.detail">
                                            <ul v-if="produit_detail===indexDesc">
                                                <li v-for="(detail, indexDetail) in desc.detail" :key="indexDetail">{{ detail }}</li>
                                            </ul>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                            <div class="div2_2_1_2_2">
                                <div class="div2_2_1_2_2_1">{{ page_rgpd().detail.bloc2.title }}</div>
                                <div class="div2_2_1_2_2_2">{{ page_rgpd().detail.bloc2.desc }}</div>
                            </div>
                            <div class="maitre_container_general">
                                <div class="maitre_container">
                                    <img class="maitre_img" src="../assets/produits_maitre_1.jpeg">
                                    <div>
                                        <b>{{ page_rgpd().detail.bloc2.m1.title }}</b>
                                        <div v-for="(detail, indexDetail) in page_rgpd().detail.bloc2.m1.desc" :key="indexDetail">{{ detail }}</div>
                                    </div>
                                </div>
                                <!--<div class="maitre_container">
                                    <div>
                                        <b>{{ page_rgpd().detail.bloc2.m2.title }}</b>
                                        <div v-for="(detail, indexDetail) in page_rgpd().detail.bloc2.m2.desc" :key="indexDetail">{{ detail }}</div>
                                    </div>
                                    <img class="maitre_img" src="../assets/produits_maitre_2.jpeg">
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div2_3">
                <div class="div2_3_left">
                    <h2 class="div2_3_left_1 will-fadeIn">{{ page_rgpd().bloc4.title }}</h2>
                    <div class="div2_3_left_2 will-fadeIn">{{ page_rgpd().bloc4.desc }}</div>
                </div>
                <div class="div2_3_right">
                    <div class="card card1 will-fadeIn fadeFromRight">
                        <div class="card_general">
                            <div class="fas_container">
                                <div class="fas fas_general fas_1">&#xf24e;</div>
                            </div>
                            <div class="card_right">
                                <div class="card_title">{{ page_rgpd().bloc4.card1.title }}</div>
                                <div class="card_text">{{ page_rgpd().bloc4.card1.desc }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card card2 will-fadeIn fadeFromRight">
                        <div class="card_general">
                            <div class="fas_container">
                                <div class="fas fas_general fas_2">&#xf007;</div>
                            </div>
                            <div class="card_right">
                                <div class="card_title">{{ page_rgpd().bloc4.card2.title }}</div>
                                <div class="card_text">{{ page_rgpd().bloc4.card2.desc }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card card3 will-fadeIn fadeFromRight">
                        <div class="card_general">
                            <div class="fas_container">
                                <div class="fas fas_general fas_3">&#xf118;</div>
                            </div>
                            <div class="card_right">
                                <div class="card_title">{{ page_rgpd().bloc4.card3.title }}</div>
                                <div class="card_text">{{ page_rgpd().bloc4.card3.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div2_4">
                <div class="div2_4_left">
                    <h2 class="div2_4_left_1 will-fadeIn">{{ page_rgpd().bloc5.title }}</h2>
                    <div class="div2_4_left_2 will-fadeIn">{{ page_rgpd().bloc5.desc }}</div>
                </div>
                <img class="produits_img2 will-fadeIn fadeFromRight" src="../assets/produits_img2.png">
                <!--<div class="div2_4_right"></div>-->
            </div>
            <div class="div2_5">
                <h2 class="div2_5_1 will-fadeIn fadeFromRight">{{ page_rgpd().bloc6.title }}</h2>
                <div class="will-fadeIn" v-for="(question, indexQuestion) in faq_rgpd[this.$store.state.codeLangue].questions" :key="indexQuestion">
                    <div class="question" @click="demanderQuestion(indexQuestion)">
                        <div class="question_titre">{{question.title}}</div>
                        <i class="fas angle_right" :class="{ angle_down : question_courante==indexQuestion}">&#xf105;</i>
                    </div>
                    <transition name="slide">
                        <div class="question_reponse" v-if="question_courante==indexQuestion">{{question.desc}}</div>
                    </transition>
                </div>
            </div>
        </div>
    </span>



    <!--article2-->
    <!--
    <span v-else-if="this.$route.params.type =='mediation'">
        <span>
            <div class="background"></div>
            <div class="inclinaison"></div>
            <div class="div1_page_mise_conformite">
                <img class="produits_img1" src="../assets/produits_img1.png">
                <div></div>
                <div class="div1_3">
                    <h1 class="div1_3_1">{{ page_mediation().bloc1.title }}</h1>
                    <div class="div1_3_2">{{ page_mediation().bloc1.desc }}</div>
                    <router-link to="#details">
                        <div class="boutton_offre">
                            <p class="texte_boutton_offre">{{ page_mediation().bloc1.bouton }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </span>
        <div>
            <div class="div2_1">
                <h2 class="div2_1_1">{{ page_mediation().bloc2.title }}</h2>
                <div class="div2_1_2">
                    <div>{{ page_mediation().bloc2.desc1 }}</div>
                    <div>{{ page_mediation().bloc2.desc2 }}</div>
                </div>
            </div>
            <div id="details" class="div2_2">
                <div class="div2_2_1">
                    <div class="div2_2_1_1">
                        <img class="product_icon" src="../assets/product_icon.png">
                        <h2 class="div2_2_1_1_1">{{ page_mediation().offre.title }}</h2>
                        <div class="div2_2_1_1_2"></div>
                        <div v-if="getRegularPrice(mediationSlug)!=getPrice(mediationSlug)" class="div2_2_1_1_3">
                            <div class="div2_2_1_1_3_1">{{ getRegularPrice(mediationSlug) }}</div>
                            <div class="div2_2_1_1_3_2"></div>
                            <div class="div2_2_1_1_3_3">€</div>
                        </div>
                        <div class="div2_2_1_1_4">
                            <div class="div2_2_1_1_4_1">{{ getPrice(mediationSlug) }}</div>
                            <div class="div2_2_1_1_4_2"></div>
                            <div class="div2_2_1_1_4_3">
                                <div class="div2_2_1_1_4_3_1">€</div>
                                <div class="div2_2_1_1_4_3_2" v-if="getInterval(mediationSlug, 1)">{{ getInterval(mediationSlug, 1) + global_translate().interval[getInterval(mediationSlug, 2)] }}</div>
                            </div>
                        </div>
                        <div class="div2_2_1_1_4_bis">{{ page_mediation().offre.info_prix }}</div>
                        <div v-if="nbEntreprises==1" class="div2_2_1_1_5">{{ page_mediation().offre.placeholder_desc }}</div>
                        <div v-else class="div2_2_1_1_5">{{ page_mediation().offre.placeholder_desc_plural }}</div>
                        <div class="div2_2_1_1_5_bis" v-for="(entreprise, indexEntreprise) in nbEntreprises" :key="indexEntreprise">
                            <input class="champUnique div2_2_1_1_6" type="text" :placeholder="page_mediation().offre.placeholder + (indexEntreprise+1)" v-model.lazy="nomsEntreprises[indexEntreprise]" />
                        </div>
                        <div class="minus_plus_div">
                            <span v-if="nbEntreprises>1" class="fas color_red pointer" @click="nbEntreprises+=-1">&#xf068;</span>
                            <span class="fas color_green pointer" @click="nbEntreprises+=1">&#xf067;</span>
                        </div>
                        <div class="allCheckbox" id="allCheckbox">
                            <div class="checkboxContainer" id="checkbox1">
                                <input class="pointer" type="checkbox" v-model="checkbox1">
                                <span class="checkboxLabel">{{ page_mediation().offre.checkbox1 }}</span>
                            </div>
                            <div class="checkboxContainer" id="checkbox2">
                                <input class="pointer" type="checkbox" v-model="checkbox2">
                                <span class="checkboxLabel">{{ page_mediation().offre.checkbox2_P1 }} <a :href="page_mediation().offre.checkbox2_link" target="_blank" class="checkboxLink">{{ page_mediation().offre.checkbox2_link_text }}</a></span>
                            </div>
                            <div class="checkboxContainer" id="checkbox3">
                                <input class="pointer" type="checkbox" v-model="checkbox3">
                                <span class="checkboxLabel">{{ page_mediation().offre.checkbox3_P1 }} <a :href="page_mediation().offre.checkbox3_link" target="_blank" class="checkboxLink">{{ page_mediation().offre.checkbox3_link_text }}</a></span>
                            </div>
                        </div>
                        <div class="div2_2_1_1_7">
                            <div @click="ajouterPanierMediation" class="boutton_offre">
                                <p class="texte_boutton_offre">{{ page_mediation().offre.bouton }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="div2_2_1_2">
                        <div class="div2_2_1_2_global">
                            <div class="div2_2_1_2_1">{{ page_mediation().detail.bloc1.title }}</div>
                            <div v-for="(desc, indexDesc) in page_mediation().detail.bloc1.desc" :key="indexDesc">
                                <div class="div2_2_1_2_4 div2_2_1_2_1_sub"> 
                                    <div>
                                        <i class="fas color_green">&#xf00c;</i>
                                    </div>
                                    <div class="div2_2_1_2_4_2">
                                        <span class="pointer pointer_hover" @click="demanderDetail(indexDesc)">{{ desc.title }}</span>
                                        <transition name="slideShort" v-if="desc.detail">
                                            <ul v-if="produit_detail===indexDesc">
                                                <li v-for="(detail, indexDetail) in desc.detail" :key="indexDetail">{{ detail }}</li>
                                            </ul>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                            <div class="div2_2_1_2_2">
                                <div class="div2_2_1_2_2_1">{{ page_mediation().detail.bloc2.title }}</div>
                                <div class="div2_2_1_2_2_2">{{ page_mediation().detail.bloc2.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div2_3">
                <div class="div2_3_left">
                    <h2 class="div2_3_left_1">{{ page_mediation().bloc4.title }}</h2>
                    <div class="div2_3_left_2">{{ page_mediation().bloc4.desc }}</div>
                </div>
                <div class="div2_3_right">
                    <div class="card card1">
                        <div class="card_general">
                            <div class="fas_container">
                                <div class="fas fas_general fas_1">&#xf24e;</div>
                            </div>
                            <div class="card_right">
                                <div class="card_title">{{ page_mediation().bloc4.card1.title }}</div>
                                <div class="card_text">{{ page_mediation().bloc4.card1.desc }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card card2">
                        <div class="card_general">
                            <div class="fas_container">
                                <div class="fas fas_general fas_2">&#xf007;</div>
                            </div>
                            <div class="card_right">
                                <div class="card_title">{{ page_mediation().bloc4.card2.title }}</div>
                                <div class="card_text">{{ page_mediation().bloc4.card2.desc }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card card3">
                        <div class="card_general">
                            <div class="fas_container">
                                <div class="fas fas_general fas_3">&#xf118;</div>
                            </div>
                            <div class="card_right">
                                <div class="card_title">{{ page_mediation().bloc4.card3.title }}</div>
                                <div class="card_text">{{ page_mediation().bloc4.card3.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div2_4">
                <div class="div2_4_left">
                    <h2 class="div2_4_left_1">{{ page_mediation().bloc5.title }}</h2>
                    <div class="div2_4_left_2">{{ page_mediation().bloc5.desc }}</div>
                </div>
                <img class="produits_img2" src="../assets/produits_img2.png">
            </div>
            <div class="div2_5">
                <h2 class="div2_5_1">{{ page_mediation().bloc6.title }}</h2>
                <div v-for="(question, indexQuestion) in faq_mediation[this.$store.state.codeLangue].questions" :key="indexQuestion">
                    <div class="question" @click="demanderQuestion(indexQuestion)">
                        <div class="question_titre">{{question.title}}</div>
                        <i class="fas angle_right" :class="{ angle_down : question_courante==indexQuestion}">&#xf105;</i>
                    </div>
                    <transition name="slide">
                        <div class="question_reponse" v-if="question_courante==indexQuestion">{{question.desc}}</div>
                    </transition>
                </div>
            </div>
        </div>
    </span>
    -->
</template>

<script>
import axios from 'axios';
import faq_rgpd_import from "../json/faq_rgpd.json";
import faq_mediation_import from "../json/faq_mediation.json";
import json_config from '@/json/config.json'
import glb_tr from "@/json/global_translate.json";
import page_prd from "@/json/page_produits.json";

export default {
  name: 'Produits',
    components: {
    },
    data(){
        return{
            glb_tr: glb_tr,
            page_prd:page_prd,
            application_type: "site",
            nbSites: 1,
            nomsSitesWeb: [],
            nbEntreprises: 1,
            nomsEntreprises: [],
            produits: '',
            idProduit: 0,
            question_courante:"undefined",
            faq_rgpd: faq_rgpd_import,
            faq_mediation: faq_mediation_import,
            defaultMiseEnConformiteSlug: "mise-en-conformite",
            miseEnConformiteSlug: "mise-en-conformite",
            mediationSlug: "mediation",
            multisiteSlug: "multisite",
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
            lesCheckbox: ["checkbox1", "checkbox2", "checkbox3"],
            produit_detail: false
        }
    },
    methods:{
        global_translate(){
            return glb_tr[this.$store.state.codeLangue]
        },
        page_rgpd(){
            return page_prd[this.$store.state.codeLangue].rgpd
        },
        page_mediation(){
            return page_prd[this.$store.state.codeLangue].mediation
        },
        demanderDetail(indexDetaiP){
            if(this.produit_detail===indexDetaiP){
                this.produit_detail=false
            }
            else{
                this.produit_detail=indexDetaiP
            }
        },
        demanderQuestion(num){
            if(this.question_courante!=num){
                this.question_courante=num
            }
            else{
                this.question_courante="undefined"
            }
        },

        getIndiceArrayOffre(offre){
            var indice = 0
            for(let i=0; i<this.produits.length; i++){
                if(this.produits[i].slug==offre){
                    indice = i
                }
            }
            return indice
        },

        getPrice(offre){
            //Si on a pas encore les produits on renvoie des petits traits, le prix charge
            if(this.produits==''){
                return "--.--";
            }
            //Sinon
            else{
                for(let i=0; i<this.produits.length; i++){
                    if(this.produits[i].slug==offre){
                        var price = this.produits[i].price
                        i=this.produits.length
                    }
                }
                return price
            }
        },

        getInterval(offre, intPart){
            //Si on a pas encore les produits on renvoie des petits traits, le prix charge
            if(this.produits==''){
                return "";
            }
            //Sinon
            else{
                for(let i=0; i<this.produits.length; i++){
                    if(this.produits[i].slug==offre){
                        var element = this.produits[i]

                        if(element.type=="subscription"){
                            var intervalStringObj = element.meta_data.find(o => o.key === '_subscription_period');
                            var intervalIntObj = element.meta_data.find(o => o.key === '_subscription_period_interval');

                            var intervalInt = intervalIntObj.value

                            if(intervalInt=="1"){
                                var interval = "/"
                            }
                            else{
                                var interval = intervalInt + "/"
                            }

                            if(intPart==1){
                                return interval
                            }
                            else if(intPart==2){
                                return intervalStringObj.value
                            }
                        }
                        //Sinon on indique qu'il n'y a pas d'interval de paiement
                        else{
                            return ""
                        }
                    }
                }
            }
        },

        getRegularPrice(offre){
            //Si on a pas encore les produits on renvoie des petits traits, le prix charge
            if(this.produits==''){
                return "--.--";
            }
            //Sinon
            else{
                for(let i=0; i<this.produits.length; i++){
                    if(this.produits[i].slug==offre){
                        var regular_price = this.produits[i].regular_price
                        i=this.produits.length
                    }
                }
                return regular_price
            }
        },

        ajouterPanier(){
            var panier=this.$store.state.panier

            var nbProd = this.$store.state.panier.length

            var onlySecondary=false

            for(var i=0; i<nbProd; i++){
                if(this.$store.state.panier[i].id==this.produits[this.getIndiceArrayOffre("mise-en-conformite")].id){
                    onlySecondary=true
                    break;
                }
            }

            var i = 0

            this.nomsSitesWeb.forEach(
                element => {
                    if(i==0 && onlySecondary==false){
                        var indiceOffre = this.getIndiceArrayOffre(this.miseEnConformiteSlug)
                    }
                    else{
                        var indiceOffre = this.getIndiceArrayOffre("multisite")
                    }

                    panier.push({id:this.produits[indiceOffre]['id'], name: this.produits[indiceOffre].categories[0]["name"], price: parseFloat(this.produits[indiceOffre]['price']),quantity: 1, meta_data:[{_key: "Site concerné", value: element, display_key: "Site concerné", display_value: element}, {_key: "Site ou application", value: this.application_type, display_key: "Site ou application", display_value: this.application_type}]})
                    
                    i++
                }
            );
            this.$store.dispatch("changerPanier", panier)

            if(this.nomsSitesWeb.length>0){
                this.$router.push('/panier')
            }
            this.nbSites=1
            this.nomsSitesWeb = []
        },

        ajouterPanierMediation(){
            //Si les trois checkbox ont été cochées
            if(this.checkbox1 && this.checkbox2 && this.checkbox3){
                var indiceOffre = this.getIndiceArrayOffre(this.mediationSlug)
                var panier=this.$store.state.panier
                this.nomsEntreprises.forEach(
                    element => panier.push({id:this.produits[indiceOffre]['id'], name: this.produits[indiceOffre].categories[0]["name"], price: parseFloat(this.produits[indiceOffre]['price']),quantity: 1, meta_data:{_key: "Entreprise", value: element, display_key: "Entreprise", display_value: element}})
                );
                this.$store.dispatch("changerPanier", panier)
                if(this.nomsEntreprises.length>0){
                    this.$router.push('/panier')
                }
                this.nbEntreprises=1
                this.nomsEntreprises = []
            }
            else{
                /*var allCheckbox = document.getElementById('allCheckbox');

                allCheckbox.classList.add('checkboxIsFalse');
          
                // remove the class after the animation completes
                setTimeout(function() {
                    allCheckbox.classList.remove('checkboxIsFalse');
                }, 300);*/

                this.lesCheckbox.forEach(
                    element=> {
                        var checkbox = document.getElementById(element)

                        var isTrue = checkbox.getElementsByTagName("input")[0].checked

                        if(!isTrue){
                            checkbox.classList.add('checkboxIsFalse');
            
                            // remove the class after the animation completes
                            setTimeout(function() {
                                checkbox.classList.remove('checkboxIsFalse');
                            }, 300);
                        }
                    }
                )
            }
        },
        total(unprix){
            var total = unprix*this.nbSites
            return total.toFixed(2);
        }
    },

    beforeCreate(){
        this.$store.state.cliquable=true
        if(this.$route.params.type){
            axios.get(json_config.phpFiles_url + "/produits.php")
            .then((result) => result.data)
            .then((result) => {
                this.produits = result;
                console.log(result)
            })  
        }
        else{
            this.$router.push("/produits/rgpd")
        }
    },

    mounted(){
        //Lorsqu'on clique sur 'ajouter au panier', il faudra voir si on peut cliquer
        //this.$store.state.cliquable=true
        if(!this.$route.params.type){
            this.$router.push("/produits/rgpd")
        }
    }
}
</script>

<!--<style scoped src="../css/cookies.css"></style>-->

<style scoped>
.multisite{
    margin-top: 20px;
    font-size: 12px;
}

.pointer_hover:hover{
    text-decoration: underline
}

.maitre_container_general{
    margin-top: 48px;
    text-align: left;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.maitre_img{
    height: 80px;
    border-radius: 50%;
}

.maitre_container{
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    align-items: center;
}
/*https://stackoverflow.com/questions/4502633/how-to-affect-other-elements-when-one-element-is-hovered */
.checkboxIsFalse {
    position: relative;
    animation: shake .1s linear;
    animation-iteration-count: 3;
}

.checkboxLink{
    color: #000000;
    text-decoration: none;
    border-bottom: 1px solid #42b983;
}

.allCheckbox{
    margin-top: 30px;
    text-align: left;
}

.checkboxContainer{
    margin-top: 10px;
    display: table
}

.checkboxLabel{
    display: table-cell;
    padding-left: 9px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.div2_2_1_2_1_sub:hover .fas{
    transform: scale(1.2);
}

.div2_2_1_2_1_sub{
    cursor: default;
}

.fas{
    transition: transform .2s;
}

.fas.pointer:hover{
  transform: scale(1.3);
}

.color_green{
    color: #42b983;
}

.color_red{
    color: #F2505D;
}

.background{
    height: 67%;
    background-color: #022873;
}

.inclinaison{
    height: 33%;
    background: linear-gradient(to bottom left, #022873 50%, transparent 50%);
}

.div1_page_mise_conformite{
    width: -webkit-fill-available;
    position: absolute;
    top: 150px;
    margin-left: 124px;
    margin-right: 184px;
    display: table;
    text-align: left;
    color: #FFFFFF;
}

.div1_1{
    width: 323px;
    height: 371px;
    background: #C4C4C4;
    display: table-cell;
    vertical-align: middle;
}

.div1_3{
    width: 571px;
    display: table-cell;
    vertical-align: middle;
}

.div1_3_1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.div1_3_2{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 27px;
    /*Si il y a pas de champ input*/
    margin-bottom:35px;
}

.div1_3_3{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 34px;
    margin-bottom: 16px;
}

.div1_3_4{
    margin-bottom: 24px;
}

.div1_3_4_1{
    border: 1px solid #E0E0E0;
}

.div2_1{
    margin-top: 147px;
    text-align: center;
}

.div2_1_1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
}

.div2_1_2{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 12px;
}

.div2_2{
    margin-top: 100px;
    max-width: 995px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.product_icon{
    position: absolute;
    left: -40px;
    top: -30px;
}

.div2_2_1{
    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 40px;
    margin-left: 40px;
}

.div2_2_1_1{
    position: relative;
    padding-right: 60px;
    padding-left: 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    text-align: -webkit-center;
    margin-left: 40px;
}

.div2_2_1_2{
    width: 504px;
    height: fit-content;
    background: #F2F3FB;
    border-radius: 10px 20px 20px 10px;
    text-align: left;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.div2_2_1_1_1{
    margin-top:45px;
    width: 265px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
    position:relative;
}

.div2_2_1_1_2{
    height: 0px;
    width: 60px;
    margin-top: 12px;
    margin-bottom: 53px;
    border: 1px solid #04D98B;
}

.div2_2_1_1_3{
    display: flex;
    justify-content: center;
    color: #CCCBCB;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
}

.div2_2_1_1_3_1{
    font-size: 24px;
    line-height: 36px;
    text-decoration-line: line-through;
}

.div2_2_1_1_3_2{
    width: 5px; 
}

.div2_2_1_1_3_3{
    font-size: 18px;
    line-height: 27px;
}

.div2_2_1_1_4{
    margin-top: 2px;
    display: flex;
    justify-content: center;
    color: #000000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    text-align: left;
}

.div2_2_1_1_4_1{
    font-size: 48px;
    line-height: 72px;
}

.div2_2_1_1_4_2{
    width: 5px; 
}

.div2_2_1_1_4_3_1{
    font-size: 24px;
    line-height: 36px;
}

.div2_2_1_1_4_3_2{
    font-size: 18px;
    line-height: 27px;
    color:#CCCBCB;
}

.div2_2_1_1_4_bis{
    font-size: 18px;
    line-height: 27px;
    color: #CCCBCB;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
}

.div2_2_1_1_5{
    margin-top: 94px;
    margin-bottom: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
}

.div2_2_1_1_6{
    margin-bottom: 52px;
    border: 1px solid #CCCBCB;
}

.div2_2_1_1_7{
    margin-top: 40px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-around;
}

.div2_2_1_2_global{
    margin-left: 35px;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-right: 62px;
}

.div2_2_1_2_1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.div2_2_1_2_4{
    display: table;
}

.div2_2_1_2_4_1{
    display: table-cell;
}

.div2_2_1_2_4_2{
    display: table-cell;
}

.div2_2_1_2_1_sub{
    margin-top: 16px;
}

.div2_2_1_2_2_1{
    margin-top: 48px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
}

.div2_2_1_2_2_2{
    margin-top: 16px;
}

i.color_green{
    padding-right: 10px;
}

ul{
    margin: 0px;
    padding-left: 17px;
}

.minus_plus_div{
    display: flex;
    justify-content: space-evenly;
}

.div2_2_1_1_5_bis{
    width: 297px;
    margin-bottom: 20px;
}

.div2_3{
    margin-top: 200px;
    display:flex;
    justify-content: center;
    align-items: center;
    max-width: 1043px;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
}

.div2_3_left_1{
    width: 398px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.div2_3_left_2{
    margin-top: 36px;
    width: 357px;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.card{
    background: #FFFFFF;
    box-shadow: 0px 2px 10px #E0E0E0;
    border-radius: 10px;
    width: 419px;
    transition: all .3s
}

.card_general{
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
}

.card:hover{
    transform: scale(1.1);
}

.card1{
    margin-left: 72px;
}

.card2{
    margin-top: 42px;
    margin-left: 226px;
}

.card3{
    margin-top: 42px;
    margin-left: 56px;
}

.card_right{
    margin-left: 24px;
}

.card_title{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.card_text{
    margin-top: 4px;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

}

.fas_general{
    border-radius: 50%;
    font-size: 16px;
    width: 34px;
    min-width: 34px;
    height: 34px;
    display: table-cell;
    vertical-align: middle;
}

.fas_container{
    display: table;
    text-align: center;
}

.fas_1{
    background: rgba(4, 217, 139, 0.3);
    color: #04D98B;
}

.fas_2{
    background: rgba(242, 80, 93, 0.5);
    color: #E8505B;
}

.fas_3{
    background: rgba(2, 40, 115, 0.5);
    color:#022873;
}

.div2_4{
    margin-top: 207px;
    padding-left: 96px;
    background-color: #022873;
    padding-top: 20px;
    padding-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #FFFFFF;
    text-align: left;
    position:relative;
}

.div2_4_left_1{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.div2_4_left_2{
    width: 494px;
    margin-top: 32px;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.produits_img2{
    position: absolute;
    top: -56px;
    right: 88px;
}

.div2_5{
    margin-top: 153px;
    margin-bottom: 140px;
}

.div2_5_1{
    margin-bottom: 63px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.question{
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0px 2px 10px #E0E0E0;
    border-radius: 10px;
    width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    transition: all .5s;
}

.question:hover{
    cursor: pointer;
    transform: scale(1.04);
}

.question_titre{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.question_reponse{
    width: 666px;
    padding-top: 49px;
    padding-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.angle_right{
    transform: rotate(0deg);
    transition: all .5s ease;
}

.angle_down{
    transform: rotate(90deg);
}

@media screen and (min-width: 1500px) {

    .div1_page_mise_conformite{
        top:100px;
        margin-left: 224px;
        margin-right: 284px;
    }

}

@media screen and (min-width: 1700px) {

    .div1_page_mise_conformite{
        top:40px;
        margin-left: 324px;
        margin-right: 384px;
    }

}

@media screen and (max-width: 1250px){
    .produits_img2{
        width:400px;
        right:50px;
    }

    .div2_4{
        padding-left: 50px
    }
}

@media screen and (max-width: 1050px){
    .div2_3{
        flex-direction: column
    }

    .div2_3_right {
        margin-top: 100px;
    }

    .div2_4_left_2{
        width: fit-content;
    }

    .produits_img2{
        position: initial;
    }
}

@media screen and (max-width: 950px){
    .div2_2_1{
        flex-direction: column;
        margin: 0px;
    }

    .div2_2_1_2{
        margin-top: 100px;
        border-radius: 20px;
    }

    .div2_2_1_1{
        margin-right: 40px;
    }
}

@media screen and (max-width: 900px){
    .div2_4{
        padding-right:50px;
        flex-direction: column;
    }

    .produits_img2{
        width: auto;
    }

    .div1_page_mise_conformite{
        margin-left:0px;
        margin-right:30px
    }

    .question{
        width:500px;
    }

    .question_reponse{
        width:400px;
    }
}

@media screen and (max-width: 700px){
    .card1, .card2, .card3{
        margin-left: 0px;
    }

    .div1_page_mise_conformite{
        display:flex;
        margin-left:30px;
    }

    .produits_img1{
        position: absolute;
        right: 0;
        bottom: -300px;
        width: 250px;
    }
}

@media screen and (max-width: 550px){
    .div2_2_1_1{
        padding: 0px;
        box-shadow: none;
    }

    .div2_2_1_1_1{
        width: min-content;
    }

    .div2_2_1_1_6{
        width: 100%!important;
    }

    .produits_img2{
        width: 350px;
    }

    .question{
        width:400px;
    }

    .question_reponse{
        width:400px;
    }
}

@media screen and (max-width: 500px){
    .div2_2_1_2{
        width:100%;
        border-radius:0px;
    }

    .div2_2_1_1_5_bis{
        width: 220px
    }

    .product_icon{
        width: 80px;
        left: -40px;
        top: 15px;
    }
}

@media screen and (max-width: 450px){
    .card{
        width: 300px;
    }

    .div2_3_left_1{
        width: 300px
    }

    .div2_3_left_2{
        width: 250px
    }

    .produits_img2{
        width: 300px;
    }

    .question{
        width:300px;
    }

    .question_reponse{
        width:250px;
    }

    .produits_img1{
        display: none
    }
}

@media screen and (max-width: 400px){
    .div1_page_mise_conformite{
        top: 100px;
    }
}

@media screen and (max-width: 350px){
    .div2_3, .div2_5{
        margin-right:25px;
        margin-left: 25px;
    }

    .div2_3_left_1, .div2_3_left_2, .card, .question{
        width: 100%
    }

    .background{
        height:100%;
    }
}

@media screen and (min-height: 700px) {

    .div1_page_mise_conformite{
        top: 120px;
    }

}

@media screen and (min-height: 780px) {

    .div1_page_mise_conformite{
        top: 219px;
    }

}
@media screen and (min-height: 880px) {

    .div1_page_mise_conformite{
        top: 249px;
    }

    .background{
        height: 60%;
    }

    .inclinaison{
        height: 40%;
    }

}

@media screen and (max-height: 400px) {
    .background{
        height: 110%;
    }

    .div1_page_mise_conformite{
        top: 110px;
    }
}

@media screen and (max-height: 450px) {
    .produits_img1{
        display: none
    }

    .div1_page_mise_conformite{
        top: 120px;
    }
}
</style>

<style>

input[type=text].champUnique{
    width: 297px;
    height: 44px;
    padding-left: 10px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
}

input[type=text].champUnique::placeholder {

    color: #D2D1D1;
}


input:focus-visible.champUnique{
    outline: #022873 auto 1px;
}
</style>